import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import {TabContext, TabList, TabPanel} from '@mui/lab';

function LabTabs(props) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`}}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={props.title1} value="1"  sx={{padding:`0px 50px`, marginRight:`2ovw`}}/>
            <Tab label={props.title2}  value="2"  sx={{padding:`0px 50px`}}/>
          </TabList>
        </Box>
        <TabPanel value="1">
        {props.interior}
        </TabPanel>
        <TabPanel value="2">
        {props.exterior}
        </TabPanel>
       
      </TabContext>
    </Box>
  );
}

export default LabTabs;
