import React from "react";
import TabContainer from "./Tabs";
import ContainerComponent from "./Container";
import TitleComponent from "./TitleComponent";
import LightHouseabc from "./Lightbox";
import InteriorOne from "../images/outdoor1.jpg";
import InteriorTwo from "../images/outdoor2.jpg";
import InteriorThree from "../images/outdoor3.jpg";
import InteriorFour from "../images/outdoor4.jpg";
import ExteriorFirst from "../images/outdoor5.jpg";
import ExteriorSecond from "../images/outdoor6.jpg";
import ExteriorThird from "../images/outdoor.jpg";


const GalleyComponent = () => {
  const interior = [
    {
      name: "Morning View",
      image: InteriorOne,
    },
    {
      name: "Play Time View",
      image: InteriorTwo,
    },
    {
      name: "Pool View",
      image: InteriorThree,
    },
    {
      name: "Sunset View",
      image: InteriorFour,
    },
  ];

  const exterior = [
    {
      name: "Jogging Track",
      image: ExteriorFirst,
    },
    {
      name: "Exterior View",
      image: ExteriorSecond,
    },
  ];
  return (
    <ContainerComponent>
      <TitleComponent title="Gallery" size="3vw" color="#7a8c57" />
      <div style={{ textAlign: "center" }}>
        <br />
        <div className="galleryImages">
          <div>
            <TabContainer
              title1="Section 1"
              title2="Section 2"
              interior={
                <LightHouseabc
                  images={[
                    InteriorOne,
                    InteriorTwo,
                    InteriorThree,
                    InteriorFour,
                  ]}
                  state={interior}
                />
              }
              exterior={
                <LightHouseabc
                  images={[ExteriorFirst, ExteriorSecond, ExteriorThird]}
                  state={exterior}
                />
              }
              mplan="Please Check other Sections"
            />
          </div>
        </div>
      </div>
    </ContainerComponent>
  );
};

export default GalleyComponent;
